import React from 'react';
import { Link } from 'gatsby';
import './index.scss';

const Footer = class extends React.Component {
  render() {
    return (
      <footer id="footer">
        <div className="footer__inner">
          <div className="footer-menu">
            <ul className="footer-menu__inner">
              <li>
                <Link to="/about">ABOUT</Link>
              </li>
              <li>
                <Link to="/projects">PROJECTS</Link>
              </li>
              <li>
                <Link to="/news/">NEWS</Link>
              </li>
              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
              <li>
                <Link to="/">TOPPAGE</Link>
              </li>
              <li className="border-left">
                <Link to="privacy-policy">PRIVACY POLICY</Link>
              </li>
            </ul>
          </div>
          <div className="footer-bottom">
            <span>©stings co.,ltd</span>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
