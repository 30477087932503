import React from 'react';
import { Link } from 'gatsby';
import logo from '../../img/STINGS_logoW.svg';
import './index.scss';

const Header = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      }
    );
  };

  render() {
    return (
      <header id="header">
        <div className="header__inner">
          <nav
            className="header-nav"
            role="navigation"
            aria-label="main-navigation"
          >
            <h1 className="header-logo">
              <Link to="/">
                <img src={logo} alt="STINGS" />
              </Link>
            </h1>
            <div
              className={`menu-trigger ${this.state.navBarActiveClass}`}
              data-target="headerMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
            <div
              id="headerMenu"
              className={`header-menu ${this.state.navBarActiveClass}`}
            >
              <ul className="header-menu__inner">
                <li>
                  <Link to="/about">ABOUT</Link>
                </li>
                <li>
                  <Link to="/projects">PROJECTS</Link>
                </li>
                <li>
                  <Link to="/news/">NEWS</Link>
                </li>
                <li>
                  <Link to="/contact">CONTACT</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  }
};

export default Header;
