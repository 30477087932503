import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import './index.scss';
import useSiteMetadata from '../SiteMetadata';
import { withPrefix } from 'gatsby';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="ja" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content="" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${withPrefix('/')}img/ogp.png`} />
        <meta property="og:url" content="/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="ja_JP" />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content="STINGS"></meta>
        <meta name="twitter:title" content={title}></meta>
        <meta name="twitter:description" content={description}></meta>
        <meta
          name="twitter:image"
          content={`${withPrefix('/')}img/ogp.png`}
        ></meta>
        <meta name="twitter:url" content="/"></meta>
        <link rel="icon" href={`${withPrefix('/')}img/favicon.ico`}></link>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon-180x180.png`}
        />
      </Helmet>
      <Header />
      <main className="wrapper">{children}</main>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
